import { css } from "@emotion/react"
import parse from "html-react-parser"
import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Block from "../components/common/Block/Block"
import Buttons from "../components/common/button"
import ColorCards from "../components/common/Color-card/ColorCards"
import CTA from "../components/common/CTA"
import FeatureCards from "../components/common/Feature-card/FeatureCards"
import Frame from "../components/common/frame"
import SparkCarousal from "../components/common/SparkCarousal"
import ColorTabToggler from "../components/common/Tab-toggler/ColorTabToggler"
import VerticalTabs from "../components/common/VerticalTabComponent/VerticalTabs"
import Navigation from "../components/navigation"
import CTAThankYou from "../components/utility/cta_thank_you"
import { SliderTestimonialCard } from "../pages/index.js"
import "../styles/feature-details-v4.scss"
import "../styles/features.scss"

function FeatureDetailsV4({ pageContext }) {
  const { feature } = pageContext

  const [screenWidth, setScreenWidth] = useState(0)
  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])
  return (
    <div className="feature-details-v4">
      <Frame seo={feature?.seos[0]} newButton>
        <header>
          <Navigation
            page="About us"
            // color={"black"}
            newButton
            darkHelloBar
            theme="dark"
            darkTheme
            btn1="secondary white"
            btn2="primary white ml-9"
          />
        </header>

        <div className="overflow-hidden">
          {feature.components.map((field, ind) => {
            switch (field.__typename) {
              case "SuperOps_Hero": {
                return (
                  <>
                    <div
                      className="hero"
                      css={css`
                        background-image: url(${screenWidth > 900
                          ? field?.images?.[0]?.url
                          : field?.images?.[1]?.url});
                      `}
                    >
                      <div className="Layout-container-big mx-auto d-flex justify-content-center">
                        <Col lg={10}>
                          <div className="text-position">
                            <p className="tag text-pink mb24 down-to-up-1">
                              {field?.tag}
                            </p>
                            <div className="down-to-up-1">
                              {parse(field?.heading?.html)}
                            </div>
                          </div>

                          <div
                            className={`d-flex flex-wrap ${
                              screenWidth > 992 ? "gap-8" : "gap-16"
                            }`}
                          >
                            <Buttons
                              theme="primary-new down-to-up-2"
                              link={field?.primaryButtonLink}
                              text={field?.primaryButtonText}
                            />
                            <Buttons
                              theme="primary-new white-new  down-to-up-2"
                              link={field?.secondaryButtonLink}
                              text={field?.secondaryButtonText}
                            />
                          </div>
                        </Col>
                      </div>
                    </div>
                    <div className="Layout-container-big mx-auto  down-to-up-5">
                      <div className="hero-image-wrap overflow-hidden">
                        <img
                          src={field?.heroImage?.url}
                          className="w-100 image"
                        />
                      </div>
                    </div>
                  </>
                )
              }
              case "SuperOps_FaqComponent": {
                return <VerticalTabs data={field} containerClass removeDotBg />
              }
              case "SuperOps_Testimonial": {
                return (
                  <section
                    className={`slider-testimonial ${field?.testimonialType}`}
                  >
                    <div className="Layout-container-big">
                      <SliderTestimonialCard
                        data={field?.peopleList[0]}
                        showQuote
                      />
                    </div>
                  </section>
                )
              }
              case "SuperOps_Block": {
                return (
                  <div
                    id={`pageBlk-${ind + 1}`}
                    className={`block-parent ${
                      field?.blockTextPosition === "Right"
                        ? "text-right"
                        : "text-left"
                    }`}
                  >
                    <Block
                      image={field?.blockImage}
                      isImage={field?.blockIsIllustration}
                      isVideo={field?.blockIsVideo}
                      lgLeft={6}
                      lgRight={6}
                      tag={field?.blockTag}
                      heading={field?.blockHeading}
                      content={field?.blockContent}
                      description={field?.blockDescription}
                      points={field?.blocksExcerpt}
                      secondaryButtonText={field?.linkName}
                      secondaryButtonLink={field?.linkSlug}
                      secondaryBtnTheme="-new"
                      containerClass
                      showBgColorImg="https://media.graphassets.com/ZaARAtEnSFeb3hpO39h5"
                      contentBelowImage
                      imageTitle={[""]}
                    />
                  </div>
                )
              }
              case "SuperOps_Benefit": {
                return (
                  <section className="benefits-section">
                    <div className="Layout-container-big mx-auto">
                      <Fade bottom duration={1000}>
                        {field?.benefitsContent?.[0]?.html ? (
                          <div className="main-heading text-center mx-auto">
                            {parse(field?.benefitsContent?.[0]?.html)}
                          </div>
                        ) : (
                          <></>
                        )}
                        {field?.benefitsTitle ? (
                          <p className="main-description mx-auto text-center p18">
                            {field?.benefitsTitle}
                          </p>
                        ) : (
                          <></>
                        )}
                      </Fade>

                      <FeatureCards
                        version={"v2"}
                        title={field?.benefitsHeading}
                        description={field?.benefitsExcerpt}
                        color={field?.benefitsLink}
                      />
                    </div>
                  </section>
                )
              }

              case "SuperOps_Card": {
                if (field.cardType === "Features")
                  return (
                    <section className="feature-cards-parent">
                      <div className="Layout-container-big mx-auto">
                        <Fade bottom duration={1000}>
                          {field?.content?.[0]?.html ? (
                            <div className="main-heading text-center mx-auto">
                              {parse(field?.content?.[0]?.html)}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Fade>
                        <FeatureCards
                          title={field?.cardHeading}
                          description={field?.title}
                          image={field?.image}
                          linkName={field?.text}
                          link={field?.links}
                          cardsPerRow={3}
                          color={field?.subtext}
                          showBgColorImgOnHover={
                            field?.subtext?.length ? false : true
                          }
                        />
                      </div>
                    </section>
                  )
                else if (field.cardType === "slideCards")
                  return (
                    <section className="toggle-images">
                      <div className="Layout-container-big mx-auto">
                        <Fade bottom duration={1000}>
                          <div className="pink-yellow-gradient">
                            <p className="tag fw-bold">{field?.slug}</p>
                          </div>
                          <div className="heading mx-auto">
                            {parse(field?.content?.[0]?.html)}
                          </div>
                        </Fade>
                        <SparkCarousal
                          carousalData={field}
                          sourceType="image"
                          fontZodiak
                        />
                      </div>
                    </section>
                  )
                else if (field.cardType === "Simple_cards")
                  return (
                    <section className="color-card-parent">
                      <Fade bottom duration={1000}>
                        <p className="tag fw-800 mb30">{field?.cardName}</p>
                      </Fade>
                      <div className="main-heading position-relative">
                        <img
                          src="https://media.graphassets.com/9E91Rly3SNSLYRGYOZdU"
                          className="left-line position-absolute d-dsk"
                        />
                        <img
                          src="https://media.graphassets.com/9E91Rly3SNSLYRGYOZdU"
                          className="right-line position-absolute d-dsk"
                        />
                        <Fade bottom duration={1000}>
                          {parse(field?.content?.[0]?.html)}
                        </Fade>
                      </div>
                      <div className="Layout-container-big mx-auto">
                        <ColorCards
                          title={field?.text}
                          description={field?.subtext}
                          image={field?.image}
                          color={field?.links}
                        />
                      </div>
                    </section>
                  )
                else if (field.cardType === "Comparison_scroll")
                  return (
                    <section className="color-toggle-parent">
                      <div className="Layout-container-big mx-auto">
                        <Fade bottom duration={1000}>
                          <p className="tag fw-800">{field?.cardName}</p>
                          <div className="main-heading">
                            {parse(field?.content?.[0]?.html)}
                          </div>
                          {field?.subtext[0] ? (
                            <p className="p20 main-description text-center mx-auto">
                              {field?.subtext[0]}
                            </p>
                          ) : (
                            <></>
                          )}
                        </Fade>
                      </div>

                      <ColorTabToggler
                        title={field?.text}
                        images={field?.image}
                      />
                    </section>
                  )
                else if (field.cardType === "Plain_cards")
                  return (
                    <section className="plain-color-cards">
                      <div className="Layout-container-big mx-auto">
                        <Fade bottom duration={1000}>
                          {field?.content?.[0]?.html ? (
                            <div className="main-content text-center">
                              {parse(field?.content?.[0]?.html)}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Fade>

                        <FeatureCards
                          title={field?.title}
                          description={field?.cardHeading}
                          image={field?.image}
                          linkName={field?.text}
                          link={field?.subtext}
                          color={field?.links}
                        />
                      </div>
                    </section>
                  )
                else if (field.cardType === "Plain_info")
                  return (
                    <div className="plain-color-info">
                      <div className="Layout-container-big mx-auto">
                        <Fade bottom duration={1000}>
                          {field?.content?.[0]?.html ? (
                            <div className="main-content text-center">
                              {parse(field?.content?.[0]?.html)}
                            </div>
                          ) : (
                            <></>
                          )}
                        </Fade>
                        <FeatureCards
                          title={field?.text}
                          description={field?.subtext}
                          color={field?.links}
                          version="v1"
                          noCarousal
                        />
                      </div>
                    </div>
                  )
                else return <></>
              }

              case "SuperOps_CtaFeature": {
                return (
                  <section className="cta-black-red-stripes mx-auto">
                    <CTA
                      data={[field?.ctaBox]}
                      containerClass
                      lgLeft={6}
                      lgRight={4}
                      newButton
                      isSecondaryBtn
                      newDesign
                    />
                  </section>
                )
              }
            }
          })}
        </div>
        <CTAThankYou />
      </Frame>
    </div>
  )
}

export default FeatureDetailsV4
